.tile__view {
    width: 100vw;
    height: 100vh;
    background: #FAFBFC;
}
.tile__menu--left {
    flex: auto;
}
.tile__menu--right {
    display: flex;
}
.tile__menu-cancel {
    display: block;
    border: none;
    background: unset;
    height: 44px;
    padding: 0;
    cursor: pointer;
}
.tile__menu-plop {
    width: 160px;
    height: 38px;
    display: grid;
    align-items: center;
    box-sizing: border-box;
}
.tile__menu-cancel:hover {
    background: #F2F3F5;
}
.tile__menu-more {
    border: none;
    background: unset;
    padding: 0;
    cursor: pointer;
    margin-left: 40px;
    position: relative;
}
.tile__menu-more-icon {
    height: 38px;
}
.tile__submenu {
    box-shadow: 0px 2px 4px 0px #4A4A4A26;
    border-radius: 8px;
    background: white;
    position: absolute;
    right: 0;
    overflow: hidden;
}
.tile__submenu-option {
    padding: 9px 16px;
}
.tile__submenu-option:hover {
    background: #F2F3F5;
}
.tile__submenu--delete {
    color: #CE4528;
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
}
.tile__browser {
    display: flex;
    flex: auto;
    position: relative;
    height: calc(100vh - 97px - 48px);
}
.tile__preview-frame {
    width: 100%;
    height: 100%;
    border: none;
}
.tile__viewer {
    flex: auto;
    position: relative;
}
.tile__viewer--fullscreen {
    width: 100vw;
    height: 100vh;
    top: 0;
    position: fixed;
    z-index: 99;
}
.tile__maximize {
    position: absolute;
    right: 30px;
    top: 30px;
    border: none;
    width: 40px;
    height: 40px;
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    z-index: 90;
}
.tile__detail {
    width: 506px;
    display: flex;
    flex: none;
    flex-direction: column;
    position: relative;
}
.tile__versions {
    overflow: auto;
    height: calc(100vh - 205px);
}
.tile__version-block {
    margin-bottom: 4px;
    border-bottom: 1px solid #F2F3F5;
}
.tile__version {
    cursor: pointer;
    padding: 24px;
    display: flex;
    background: white;
    position: relative;
}
.tile__version-avatar {
    width: 24px;
    height: 24px;
    background: black;
    border-radius: 50%;
    margin-right: 16px;
    color: white;
    text-align: center;
    line-height: 24px;
}
.tile__version-contents {
    flex: auto;
    display: flex;
    flex-direction: column;
}
.tile__version-info {
    display: flex;
    margin-bottom: 4px;
    align-items: center;
}
.tile__version-info-spacer {
    margin-left: 8px;
    margin-right: 8px;
}
.tile__version-name {
    flex: auto;
}
.tile__version-index {
    color: #513CCC;
}
.tile__version-comment-count {
    font-weight: 400; 
    margin-left: 4px;
}
.tile__comments {
    padding-left: 35px;
    background: white;
}
.tile__comment {
    padding: 12px 24px 12px 29px;
    display: flex;
    position: relative;
}
.tile__comment-avatar {
    width: 24px;
    height: 24px;
    background: black;
    border-radius: 50%;
    margin-right: 16px;
    color: white;
    text-align: center;
    line-height: 24px;
}
.tile__comment-contents {
    flex: auto;
    display: flex;
    flex-direction: column;
}
.tile__comment-info {
    display: flex;
    margin-bottom: 4px;
    align-items: center;
}
.tile__comment-name {
    flex: auto;
}

.tile__ellipsis {
    margin-left: 8px;
    cursor: pointer;
}
.tile__input-block {
    background: white;
    border-top: 1px solid #F2F3F5;
}
.tile__input {
    padding: 0 24px;
    box-sizing: border-box;
    width: 100%;
    display: block;
    position: relative;
}
.tile__input-target {
    margin: 8px 24px;
}
.tile__input input {
    width: 100%;
    padding: 18px 64px 18px 24px;;
}
.tile__submit-comment {
    position: absolute;
    border: none;
    top: 18px;
    right: 48px;
    width: 24px;
    height: 24px;
    padding: 0;
    background: none;
    cursor: pointer;
}
.tile__bottom {
    height: 48px;
    width: 100%;
}
