.uploader {
    height: 100vh;
    width: 100vw;
    position: fixed;
    background: #00000080;
    z-index: 99;
}
.uploader__form--file {
    max-height: 283px;
    width: 626px;
    border-radius: 8px;
    background: #F2F3F5;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 32px;
    box-sizing: border-box;
    transition: 500ms all ease;
    overflow: auto;
}
.uploader__form--full {
    max-height: 695px;
    height: fit-content;
}
.uploader__form--full--selected {
    max-height: 562px;
    height: fit-content;
}
.uploader__form--link {
    max-height: 527px;
}
.uploader__form--link .uploader__form-file {
    display: none;
}

.uploader__file {
    display: none;
}
.uploader__form-link {
    height: 60px;
    background: white;
    border: 1px dashed #D5D8DF;
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 12px 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}
.uploader__form-link-input {
    border: none;
    padding: 0 12px;
    height: 100%;
    width: 100%;
}
.uploader__link-icon {
    height: 24px;
    margin-right: 16px;
}
.uploader__form-file {
    height: 143px;
    background: white;
    border: 1px dashed #D5D8DF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 26px 0 30px 0;
    box-sizing: border-box;
}
.uploader__form-file--selected {
    height: unset;
}
.uploader__file-browse {
   color: #513CCC; 
   text-decoration: underline;
   margin: 0 6px;
   cursor: pointer;
}
.uploader__form--meta {
    /* height: 396px; */
    width: 446px;
    border-radius: 8px;
    background: #F2F3F5;
    background: white;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 32px;
    box-sizing: border-box;
}
.uploader__form-label {
    display: block;
    margin-bottom: 8px;
    margin-top: 16px;
}
.uploader__form-input {
    height: 38px;
    padding: 8px 16px;
    width: 100%;
    margin-bottom: 12px;
    cursor: pointer;
}
.uploader__form-textarea {
    width: 100%;
    cursor: pointer;
    margin-bottom: 24px;
}
.uploader__form-meta-btns {
    display: flex;
    justify-content: flex-end;
}
.uploader__form-meta-cancel {
    /*width: 72px;*/
    margin-right: 10px;
}
.uploader__form-meta-submit {
    width: 120px;
}
.uploader__divider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
}
.uploader__divider-name {
    margin: 0 16px;
}
.uploader__divider {
    /*
    width: 80%;
    height: 1px;
    background: #D5D8DF;
    margin-top: 48px;
    margin-bottom: 36px;
    position: relative;
    transform: translate(-50%);
    left: 50%;
*/
    width: 35%;
    height: 1px;
    background: #D5D8DF;
    /* margin-top: 48px; */
    /* margin-bottom: 36px; */
    position: relative;
    /* transform: translate(-50%); */
    /* left: 50%;*/
}
