.renderer {
    height: 100%;
    width: 100%;
}
.renderer__placeholder {
    height: 100%;
    width: 100%;
    background: whitesmoke;
    background-image: url('../../assets/placeholder.png');
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;
}
.renderer__view {
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.renderer__bg--light {
    background: white;
}
.renderer__bg--dark {
    background: black;
}

/* --- image renderer --- */
.renderer__canvas {
    height: 100%;
    width: 100%;
}
.renderer__img {
    /* display: none; */
    object-fit: cover;
    width: 100%;
    height: 100%;
}

/* --- renderer download ---*/
.renderer__download {
    width: 80%;
    max-width: 300px;
    display: block;
    top: 50%;
    position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
}
.renderer__download-message {
    text-align: center;
    margin-bottom:16px;
}
.renderer__download-btn {
    display: block;
    left: 50%;
    position: relative;
    transform: translate(-50%);
    height: 38px;
    width: 120px;
}
.renderer__content {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.renderer__content--text {
    background: white;
}
.renderer__download-link { display: none; }

/* --- audio renderer ---*/
.renderer__content--audio {
    width: 80%;
    top: 50%;
    position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* --- video renderer --- */
.renderer__content--video {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* --- frame renderer --- */
.renderer__content--frame {
    width: 200%;
    height: 200%;

    -ms-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    -webkit-transform: scale(0.5);
    transform: scale(0.5);

    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}
