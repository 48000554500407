.error__view {
    height: calc(100vh - 97px);
}   
.error__panel {
    position: relative;
    top: 50%;
    left: 50%;
    width: 500px;
    transform: translate(-50%, calc(-50% - 120px));
}
.error__header {
    text-align: center;
    margin-bottom: 12px;
}
