.navbar {
    width: 100%;
    height: 96px;
    background: white;
    border-bottom: 1px solid #F2F3F5;
    position: relative;
    z-index: 80;
}
.navbar__menu {
    display: flex;
    align-items: center;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    margin: auto 37px auto 66px;
}

.navbar__logo {
    height: 42px;
    width: 42px;
    flex: auto;
}
.navbar__logo img {
    width: auto;
    height: 100%;
}

.navbar__new-btn {
    width: 120px;
    height: 38px;
    box-sizing: border-box;
}
.navbar__alerts {
    margin-left: 60px;
    cursor: pointer;
    position: relative;
}
.navbar__notifications {
    position: absolute;
    width: 491px;
    right: 0;
    box-shadow: 0px 2px 4px 0px #4A4A4A26;
    background: white;
    border-radius: 8px;
    overflow: auto;
    max-height: calc(100vh - 100px);
}
.navbar__notifications-header {
    padding: 40px 30px 36px 40px;
}
.navbar__notification {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    background: #FAFBFC;
    border-bottom: 1px solid #F2F3F5;
}
.navbar__notification-icon {
    flex: none;
    height: 32px;
    width: 32px;
    background: black;
    border-radius: 50%;
}
.navbar__notification-content{
    flex: auto;
    display: flex;
    flex-direction: column;
    margin: auto 24px;
}
.navbar__notification-title { }
.navbar__notification-body {
    margin-top: 4px;
}
.navbar__notification-timestamp {
    font-family: DM Sans;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.20000000298023224px;
}
.navbar__account-menu {
    box-shadow: 0px 2px 4px 0px #4A4A4A26;
    border-radius: 8px;
    background: white;
    position: absolute;
    right: 0;
    overflow: hidden;
}
.navbar__account-option {
    padding: 9px 16px;
    font-family: DM Sans;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
}
.navbar__account-option:hover {
    background: #F2F3F5;
}

.navbar__avatar {
    height: 40px;
    width: 40px;
    color: white;
    border-radius: 50%;
    background: #36B368;
    background: black;
    line-height: 40px;
    text-align: center;
    margin-left: 16px;
    cursor: pointer;
}
