.auth__view {
    height: calc(100vh - 97px);
    width: 100vw;
}
.auth__forms {
    width: fit-content;
   position: relative;
   left: 50%;
   top: 50%;
   transform: translate(-50%, calc(-50% - 97px));
}
.login__form {
    width: 443px;
}
.login__header {
    text-align: center;
    margin-bottom: 40px;
}
.login__label,
.signup__label {
    display: block;
    margin-bottom: 8px;
}
.login__input {
    width: 100%;
    height: 40px;
    margin-bottom: 16px;
}
.login__btn {
    margin-top: 24px;
    width: 100%;
}

.signup__form {
    width: 443px;
    margin-top: 100px;
}
.signup__instructions {
    text-align: center;
    white-space: pre-wrap;
    margin-bottom: 40px;
}
.signup__input-group {
    display: flex;
}
.signup__input {
    flex: auto;
    margin-right: 12px;
}
