body {
    margin: 0;
}

#plop-root {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    min-width: 100vw;
}

/* --- typography --- */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
.title-1 {
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
}
.title-2 {
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
}
.title-3 {
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}
.title-4 {
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
}
.subtitle-1 {
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}
.subtitle-2 {
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
}
.subtitle-3 {
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.subtitle-4 {
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
}
.body-1 {
    font-family: 'DM Sans';
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
}
.body-2 {
    font-family: 'DM Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.body-3 {
    font-family: 'DM Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
}
.label {
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.2px;
}
.metadata {
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.2px;
}

.metadata-sm-btn {
    font-family: 'DM Sans';
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
}


/* --- colors --- */
.ink70 { color: #000000; }
.ink60 { color: #212224; }
.ink50 { color: #393B3F; }
.ink40 { color: #60636A; }
.ink30 { color: #A4A8AE; }
.white { color: #FFFFFF; }

.neutral70 { color: #B8BEC9; }
.neutral60 { color: #D5D8DF; }
.neutral50 { color: #F2F3F5; }
.neutral40 { color: #FAFBFC; }

.red90 { color: #A83018; }
.red70 { color: #CE4528; }
.red50 { color: #EA5230; }
.red30 { color: #F68A6E; }
.red10 { color: #F8AA97; }

.green90 { color: #106132; }
.green70 { color: #259151; }
.green50 { color: #36B368; }
.green30 { color: #77CA95; }
.green10 { color: #C4E7D0; }

.yellow90 { color: #FF9100; }
.yellow70 { color: #FFA200; }
.yellow50 { color: #FFC400; }
.yellow30 { color: #FFD74F; }
.yellow10 { color: #FFEDB3; }

.blue90 { color: #001EA9; }
.blue70 { color: #3430BD; }
.blue50 { color: #513CCC; }
.blue30 { color: #8B76DC; }
.blue10 { color: #CEC4EF; }
.blue5 { color: #F0EEF9; }

/* --- position --- */
.centered {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.centered-h {
    position: relative;
    left: 50%;
    transform: translate(-50%);
}
.centered-v {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
}

/* --- modals --- */
.modal {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: white;
  width: auto;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  border-radius: 8px;
}

.modal-active {
    display: block;
}

.modal-inactive {
    display: none;
}

/* --- cards --- */
.card-sm {
    box-shadow: 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
                0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
                0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    border-radius: 4px;
}
.card-md {
    box-shadow: 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
                0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
                0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
                0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    border-radius: 8px;
}
.card-lg {
    box-shadow: 0px 20px 33.4221px rgba(0, 0, 0, 0.0503198),
                0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
                0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
                0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
                0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    border-radius: 16px;
}

/* --- buttons --- */
.btn {
    font-family: 'DM Sans';
    font-weight: 700;
    line-height: 18px;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    padding: 12px 16px;
    cursor: pointer;
}
.btn-sm {
    font-family: 'DM Sans';
    font-weight: 700;
    line-height: 18px;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    padding: 8px 12px;
    cursor: pointer;
}
.btn-right {
    float: right;
}
.btn-left {
    float: left;
}
.btn-primary {
    color: white;
    background-color: #513CCC;
    border: none;
}
.btn-primary.loading {
    background-color: #001EA9;
}
.btn-primary:disabled {
    background-color: #F0EEF9;
    color: #8B76DC;
}
.btn-secondary {
    color: #513CCC;
    background-color: white;
    border: 1px solid #513CCC;
}
.btn-secondary.loading {
    border: 1px solid #001EA9;
}
.btn-secondary:disabled {
    color: #8B76DC;
    border: 1px solid #CEC4EF;
}
.btn-tertiary {
    color: #513CCC;
    background-color: white;
    border: none;
}
.btn-tertiary:disabled {
    color: #8B76DC;
}

/* --- actions --- */
.tag {
    background-color: #F2F3F5;
    color: #393B3F;
    border-radius: 40px;
    text-align: center;
    padding: 8px 16px;
}
.tag:hover {
    background-color: #D5D8DF;
}
.tag.selected {
    color: white;
    background-color: #513CCC;
}

/* --- forms --- */
.form-row {
    width: 100%;
    overflow: auto;
}
.form-label {
    margin-bottom: 8px;
}
input[type=text],
input[type=email],
input[type=password],
textarea {
    color: #393B3F;
    border: 1px solid #D5D8DF;
    border-radius: 4px;
    padding: 8px 16px;
    box-sizing: border-box;
}
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
textarea:focus {
    border: 1px solid #513CCC;
}
input[type=text]:hover,
input[type=email]:hover,
input[type=password]:hover,
textarea:hover {
    border: 1px solid #513CCC;
}
input::placeholder,
textarea::placeholder {
    color: #A4A8AE;
}
.error {
    background-color: #FACBBF;
    border: 1px solid #EA5230;
}
