.page__view {
    min-height: 100vh;
    width: 100vw;
    background: #FAFBFC;
}

.board__view {
    padding: 0 80px 36px 80px;
}

.board__info {
    margin-top: 40px;
    display: flex;
    align-items: center;
}
.board__avatar {
    width: 60px;
    height: 60px;
    background: black;
    border-radius: 50%;
    margin-right: 16px;
}

.board__dashboard {
    width: 100%;
    margin-top: 87px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 368px);
    gap: 24px;
    justify-content: center;
}

.board__tile-skeleton { 
    width: 368px;
    height: 466px;
    border-radius: 8px;
    border: 2px dashed #D5D8DF;
    cursor: pointer;
}
.board__tile-skeleton-content {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}
.board__tile-skeleton-btn {
    margin-right: 12px;
}

.tile {
    width: 368px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0px 2px 4px 0px #4A4A4A26;
}

.tile__preview {
    width: 100%;
    height: 232px;
    pointer-events: none;
}
.tile__render {
    display: block;
    object-fit: cover;
}
.tile__frame {
    pointer-events: none;
    border: none;
    height: 400%;
    width: 400%;
    -ms-transform: scale(0.25);
    -moz-transform: scale(0.25);
    -o-transform: scale(0.25);
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    display: none;
}
.tile__frame--visible {
    display: block;
}
.tile__preview img {
    height: 100%;
    width: 100%;
    position: relative;
}

.tile__body {
    background: white;
    padding: 16px 16px 24px 16px;
    box-sizing: border-box;
}

.tile__title {
    font-family: 'DM Sans';
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 32px;
}

.tile__description {
    height: 80px;
    margin-bottom: 24px;
}
.tile__meta {
    display: flex;
    align-items: center;
    font-family: 'DM Sans';
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.2px

}
.tile__meta-user {
    width: 24px;
    height: 24px;
    background: #36B368;
    border-radius: 50%;
    margin-right: 6px;
    text-align: center;
    color: white;
    line-height: 24px;
}
.tile__meta-username {
}
.tile__meta-divider {
    margin: auto 8px;
}
.tile__meta-comments {
    display: flex;
    align-items: center;
}
.tile__meta-comment-count {
    margin-left: 4px;
}
